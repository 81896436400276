import React from "react";
import Image from "next/image";
import Link from "next/link";
import bagIcon from "assets/Bag-Icon.svg";
import cloutB2bLogo from "assets/Clout-Logo-B2B.svg";
import cloutMarketplaceLogo from "assets/Clout-Logo-Marketplace.svg";
import fileIcon from "assets/File-Icon.svg";
import rightIcon from "assets/right-icon-white.svg";
import { useRouter } from "next/router";
import { redirectUrlWithParams } from "utils/misc";
import CloutBusinessInfoPoints from "./CloutBusinessInfoPoints";
import CloutMarketplaceInfoPoints from "./CloutMarketplaceInfoPoints";
import metaData from "./metaData";

const CloutWelcomePage = () => {
  const router = useRouter();

  return (
    <div className="smScreen:hidden flex flex-col gap-[32px] items-center w-full min-h-screen px-[36px] py-[56px] max-w-[904px] mx-auto">
      <div className="text-center text-[#000000]">
        <h1 className="text-2xl font-bold">Welcome to Roposo Clout!</h1>
        <p>{metaData.introPara}</p>
      </div>

      <div className="w-full flex gap-[32px] text-[#424242]">
        {/* Marketplace Card */}
        <div>
          <div className="bg-white rounded-[16px] shadow-md p-[30px] flex flex-col max-w-[400px]">
            <div className="mb-[30px]">
              <div className="flex items-center justify-center mb-[30px]">
                <Image
                  src={cloutMarketplaceLogo}
                  alt="Roposo Clout Marketplace"
                  width={174}
                  height={40}
                />
              </div>
              <hr className="mb-[30px] border-[#C2C2C2]" />

              <CloutMarketplaceInfoPoints />
            </div>

            <hr className="mb-[30px] border-[#C2C2C2]" />

            <div className="flex items-start mb-[30px] gap-2">
              <div className="w-[32px] h-[32px]">
                <Image 
                  src={fileIcon} 
                  alt="File Icon" 
                  width={32} 
                  height={32}
                />
              </div>
              <p className="text-sm italic flex-1">{metaData.marketplace.idealUser}</p>
            </div>

            <Link 
              href={redirectUrlWithParams({ pathname: '/marketplace/login', query: router.query })}
              passHref
            >
              <a className="bg-[#0A0A0A] text-white rounded-[4px] w-full py-[10px] flex gap-1 items-center justify-center font-bold">
                Get Started
                <Image
                  src={rightIcon}
                  alt="Right Icon"
                  width={24}
                  height={24}
                  className="ml-[10px]"
                />
              </a>
            </Link>
          </div>
        </div>

        {/* Business Card */}
        <div className="bg-white rounded-[16px] shadow-md p-[30px] flex flex-col max-w-[400px]">
          <div className="mb-[30px]">
            <div className="flex items-center justify-center mb-[30px]">
              <Image
                src={cloutB2bLogo}
                alt="Roposo Clout Marketplace"
                width={174}
                height={40}
              />
            </div>
            <hr className="mb-[30px] border-[#C2C2C2]" />

            <CloutBusinessInfoPoints />
          </div>

          <hr className="mb-[30px] border-[#C2C2C2]" />

          <div className="flex items-start mb-[30px] gap-2">
            <div className="w-[32px] h-[32px]">
              <Image 
                src={bagIcon} 
                alt="Bag Icon" 
                width={32} 
                height={32} 
              />
            </div>
            <p className="text-sm italic flex-1">{metaData.b2b.idealUser}</p>
          </div>

          <Link 
            href={redirectUrlWithParams({ pathname: '/b2b/login', query: router.query })}
            passHref
          >
            <a className="bg-[#0A0A0A] text-white rounded-[4px] w-full py-[10px] flex gap-1 items-center justify-center font-bold">
              Get Started
              <Image
                src={rightIcon}
                alt="Right Icon"
                width={24}
                height={24}
                className="ml-[10px]"
              />
            </a>
          </Link>

          <p className="text-xs mt-[10px] max-w-md text-[#424242] font-semibold">
            {metaData.b2b.note}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CloutWelcomePage;

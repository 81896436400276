import CloutWelcomePage from 'components/CloutWelcomePage';
import { PageContainer } from 'components/common';
import FullPageLayout from 'components/Layout/FullPageLayout/_default/FullPageLayout';
import Meta from 'components/Meta/_default';
import { storeTypeValues } from 'constants/route';
import handleLogout from 'handlers/logout';
import type {NextPageContext} from 'next'
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {getStoreType, isUserLoggedIn} from "utils/auth";
import {getPathNameWithStoreType} from 'utils/misc';
import dynamic from 'next/dynamic';
import useBreakpoint from 'hooks/useBreakpoint';
import { Screen } from 'constants/breakpoints';

const CloutMobileWelcomePage = dynamic(() => import('components/CloutWelcomePage/CloutMobileWelcomePage'));

const CloutWelcomeScreen = () => {
  const isSmScreen = useBreakpoint(Screen.SmScreen);
  const router = useRouter();

  useEffect(() => {
    //check if user is logged in and storeType is not valid then logout.
    if (isUserLoggedIn() && !storeTypeValues.includes(getStoreType())) {
      handleLogout(router);
    }
  }, []);

  return (
    <PageContainer className='smScreen:!px-0 smScreen:!pb-0'>
      <Meta title='Ropso Clout' />
      {!isSmScreen && <CloutWelcomePage />}
      {isSmScreen && <CloutMobileWelcomePage />}
    </PageContainer>
  );
}

export const getServerSideProps = async (ctx: NextPageContext) => {
  if (isUserLoggedIn(ctx) && storeTypeValues.includes(getStoreType(ctx))) {
    return {
      redirect: {
        destination: `${getPathNameWithStoreType('', ctx)}`, // Redirect to Home page with respective storeType
        permanent: false,
      },
    };
  } else {
    return {
      props: {},
    };
  }
}

CloutWelcomeScreen.getLayout = (page: React.ReactElement) => <FullPageLayout wrapperClass='!bg-[#F5F5F5]'>{page}</FullPageLayout>

export default CloutWelcomeScreen;


import Image from "next/image";
import checkCircleIcon from "assets/CheckCircleWithBg.svg";

const CloutMarketplaceInfoPoints = () => {
  return (
    <ul className="space-y-4 smScreen:space-y-2 text-sm smScreen:text-xs">
      <li className="flex items-start gap-2">
        <div className="w-[16px] h-[16px] bg-[] flex justify-center items-center rounded-full">
          <Image
            src={checkCircleIcon}
            alt="Check Circle Icon"
            width={16}
            height={16}
          />
        </div>
        <div className="flex-1">
          <p>
            If you are GST Registered Businesses, Get GST input benefits
            directly <span className="font-semibold">from suppliers</span>.
          </p>
        </div>
      </li>
      <li className="flex items-start gap-2">
        <div className="w-[16px] h-[16px] bg-[] flex justify-center items-center rounded-full">
          <Image
            src={checkCircleIcon}
            alt="Check Circle Icon"
            width={16}
            height={16}
          />
        </div>
        <div className="flex-1">
          <p>
            <span className="font-semibold">Suppliers generate</span> invoices
            in your name.
          </p>
        </div>
      </li>
      <li className="flex items-start gap-2">
        <div className="w-[16px] h-[16px] bg-[] flex justify-center items-center rounded-full">
          <Image
            src={checkCircleIcon}
            alt="Check Circle Icon"
            width={16}
            height={16}
          />
        </div>
        <div className="flex-1">
          <p>
            GST input liability is{" "}
            <span className="font-semibold">handled by the supplier</span>.
          </p>
        </div>
      </li>
      <li className="flex items-start gap-2">
        <div className="w-[16px] h-[16px] bg-[] flex justify-center items-center rounded-full">
          <Image
            src={checkCircleIcon}
            alt="Check Circle Icon"
            width={16}
            height={16}
          />
        </div>
        <div className="flex-1">
          <p>
            Providing GST details is{" "}
            <span className="font-semibold">optional</span>.
          </p>
        </div>
      </li>
    </ul>
  );
};

export default CloutMarketplaceInfoPoints;

import {isStaff, staffEmail} from "../utils/auth";
import {sessionLogout, staffSessionLogout} from "./login";
import deleteAllCookies from "./killCookies";
import { getLoginUrlWithStoreType, getStaffLoginUrlWithStoreType } from "utils/misc";

const handleLogout = (router: any) => {
  const email =staffEmail();
  if(isStaff()){
    staffSessionLogout(
      () => {
        const redirectStaffLoginUrl = getStaffLoginUrlWithStoreType();
        deleteAllCookies();

        if (typeof window !== 'undefined') window.location.href = redirectStaffLoginUrl;
        else router.push(redirectStaffLoginUrl);
      },email
    );
  }else{
    sessionLogout(
      () => {
        const redirectLoginUrl = getLoginUrlWithStoreType();
        deleteAllCookies();

        if (typeof window !== 'undefined') window.location.href = redirectLoginUrl;
        else router.push(redirectLoginUrl);
      }
    );
  }

};
export default handleLogout;